import React from 'react';
import { useTranslation } from 'react-i18next';

const CheckboxOption = ({
                            field,
                            form,
                            id,
                            label,
                            description,
                            frequency,
                            alignTop,
                            required,
                        }) => {
    const { t } = useTranslation();
    const { name, value } = field;
    const { touched, errors } = form;
    const hasErrors = touched[name] && errors[name];

    const handleChange = () => {
        form.setFieldValue(name, !value);
    };

    return (
        <div className="flex items-start gap-5 w-full">
            <div className={`w-full `}>
                <label
                    htmlFor={id}
                    className={`group flex select-none  ${
                        alignTop ? 'items-start' : 'items-center'
                    }  h-full gap-5 hover:cursor-pointer `}
                    onClick={handleChange}
                >
                    <div
                        className={`flex-none flex items-center justify-center size-5 md:size-6 border border    ${
                            value ? 'bg-primary border-primary' : 'bg-gray-200 border-gray-200'
                        }`}
                    >

                        {value && (<>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3"
                                 stroke="currentColor" className="size-4 text-white">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5"/>
                            </svg>

                        </>)}

                    </div>
                    <div className={`grow`}>
                        {!description && (<div className={`text-base mb-0.5 ${required ? 'required' : ''}`}>{label}</div>)}
                        {description && (
                            <>
                                <div className={`font-bold text-base md:text-base mb-0.5  ${required ? 'required' : ''}`}>{label}</div>
                                <div className="text-sm md:text-sm font-light mb-0.5">
                                    {description}
                                </div>
                            </>
                        )}
                        {frequency && (
                            <div className="text-sm font-semibold text-gray-500">
                                <span className={`mr-0.5`}>{t('Frequency')}:</span> <span className={``}>{frequency}</span>
                            </div>
                        )}
                    </div>
                </label>

            </div>
        </div>
    );
};

export default CheckboxOption;
