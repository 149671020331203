import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import FrontPage from './components/FrontPage';



import { getBrowserLanguage } from './utils';




const App = () => {

  return (
      <div>
        <Router>
          <Routes>
            <Route path="/" element={<FrontPage />} />

          </Routes>
        </Router>
      </div>
  );
};

export default App;
