import React from 'react';

const RadioOption = ({ field, form, id, value, label }) => {
    const { name } = field;
    const { touched, errors } = form;
    const hasErrors = touched[name] && errors[name];

    const handleChange = () => {
        form.setFieldValue(name, value);
    };

    const checked = field.value === value;

    return (
        <label
            htmlFor={id}
            className={`flex gap-5 w-full h-full items-center  select-none cursor-pointer`}
            onClick={handleChange}
        >

            <div
                className={`flex-none flex items-center justify-center size-5 md:size-8 border border  rounded-full ${
                    checked ? 'bg-primary border-primary' : 'bg-gray-200 border-gray-200'
                }`}
            >

                {checked && (<>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3"
                         stroke="currentColor" className="size-4 text-white">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5"/>
                    </svg>

                </>)}

            </div>


            <div className="hidden">
                <input
                    {...field}
                    id={id}
                    type="radio"
                    value={value}
                    checked={checked}
                    onChange={() => {
                    }}
                    className="hidden peer"
                />
            </div>
            {label}
        </label>
    );
};

export default RadioOption;
