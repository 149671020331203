import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Header from "./Header";
import {useTranslation, Trans} from "react-i18next";
import { getBrowserLanguage } from '../utils';
import NewsletterSubscriptionForm from "./NewsletterSubscriptionForm";
const FrontPage = () => {
    const supportedLangs = ['fr', 'de'];

    const navigate = useNavigate();
    const { lang } = useParams(); // Assuming the URL is like /:lang/survey
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const [langcode, setLangcode] = useState(() => {
        const browserLang = getBrowserLanguage();
        return supportedLangs.includes(browserLang) ? browserLang : 'fr';
    });

    const [hasExistingSession, setHasExistingSession] = useState(false);


    const [lastPage, setLastPage] = useState(null);
    const [countAnswers, setCountAnswers] = useState(0);
    const [darkMode, setDarkMode] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showDataProtectionModal, setShowDataProtectionModal] = useState(false);
    const [honeypot, setHoneypot] = useState('');
    const [honeypot2, setHoneypot2] = useState('');
    const [honeypot3, setHoneypot3] = useState('');
    const [honeypot4, setHoneypot4] = useState('');
    const [honeypot5, setHoneypot5] = useState('');
    const [honeypot6, setHoneypot6] = useState('');
    const [honeypot7, setHoneypot7] = useState('');
    const [honeypot8, setHoneypot8] = useState('');
    const [dataProtectionConsent, setDataProtectionConsent] = useState(false);
    const [errorDataProtectionConsent, setErrorDataProtectionConsent] = useState('');


    // Create a ref for the target section
    const targetSectionRef = useRef(null);

    // Event handler for clicking the arrow icon
    const scrollToSection = () => {
        targetSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        document.documentElement.lang = langcode;
        i18n.changeLanguage(langcode);
    }, [langcode, lang, i18n]);

    const onLanguageChange = async (language) => {
        i18n.changeLanguage(language);
        setLangcode(language); // Update state with the new language
    }

    function closeModal() {
        setShowModal(false);
    }
    function closeDataProtectionModal() {
        setShowDataProtectionModal(false);
    }


    function openDataProtectionModal(){
        setShowDataProtectionModal(true);
    }

  return (
      <div className=" flex flex-col min-h-screen w-full">
          <Header onLanguageChange={onLanguageChange} />
          <NewsletterSubscriptionForm />
        </div>
  );
};

export default FrontPage;
