import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en.json';
import translationDE from './locales/de.json';
import translationFR from './locales/fr.json';
import translationLB from './locales/lb.json';

// the translations
const resources = {
    en: { translation: translationEN },
    de: { translation: translationDE },
    fr: { translation: translationFR },
    lb: { translation: translationLB },
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'fr', // default language
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;