import React from 'react';

const TextField = ({ field, form, label, placeholder = '', required = false, ...props }) => {
    const { name } = field;
    const { touched, errors } = form;
    const hasErrors = touched[name] && errors[name];

    return (
        <div className="flex flex-col gap-2 w-full">
            <label htmlFor={name} className="text-lg md:text-xl font-bold  required mb-2 required">
                {label}
            </label>
            <input
                {...field}
                {...props} // Pass additional props here
                id={name}
                className={`block border p-3 outline-none focus:ring-0 w-full placeholder:text-gray-400 placeholder:text-sm text-sm md:text-base ${
                    hasErrors ? 'bg-gray-200 border-red-600 ' : 'bg-gray-200 border-gray-200'
                }`}
                placeholder={placeholder}
                required={required}
            />
            {hasErrors && <div className="text-red-600 font-bold">{errors[name]}</div>}
        </div>
    );
};

export default TextField;
