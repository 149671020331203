import React, {useEffect, useState} from 'react';
import { useTranslation, Trans } from 'react-i18next';

const SuccessMessage = React.memo(() => {
    const { t, i18n } = useTranslation();
    return (
<div className="w-full bg-green-600 p-5 md:p-8 text-white drop-shadow-lg mt-10 rounded-lg">
    {/* Success Message */}
    <div className="flex items-center gap-5 text-xl md:text-4xl font-bold mb-5">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="flex-none w-10 md:w-20"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
        </svg>
        <span className="grow">{t('newsletter_success_title')}</span>
    </div>
    <div className="rich-text-workshop-success-message md:text-lg">
        <Trans
            i18nKey={'newsletter_success_message'}
            components={{
                h3: <h3 />,
                p: <p />,
                strong: <strong />,
                a: <a />,
            }}
        />
    </div>
</div>

    );
});



export default SuccessMessage;