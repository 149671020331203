import React, { useEffect } from 'react';
import logo from "./logo.svg";
import {useTranslation} from "react-i18next";

const Header =  React.memo(({ darkMode, onLanguageChange, onDarkModeChange }) => {
    const { i18n } = useTranslation();

    const changeLanguage = (language) => {
        onLanguageChange(language);
    };



    const handleDarkModeToggle = () => {
        onDarkModeChange(!darkMode);
    };


    useEffect(() => {
        if (darkMode) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
    }, [darkMode]);

    const { t } = useTranslation();




    const LanguageButton = ({ title, langcode = 'fr' }) => {
        const BUTTON_SIZE = "size-8 md:size-10"; // 3rem x 3rem

        const BUTTON_STYLES = `relative flex items-center justify-center ${BUTTON_SIZE} rounded-full cursor-pointer transition-all duration-300 ease-in-out border`;

        const ACTIVE_STYLES = "bg-white border-2 border-white drop-shadow-lg";
        const INACTIVE_STYLES = "bg-transparent border-transparent hover:border-white ";

        const isActive = i18n.language === langcode;
        const buttonClassName = `${BUTTON_STYLES} ${isActive ? ACTIVE_STYLES : INACTIVE_STYLES}`;

        return (
            <button
                title={title}
                onClick={() => changeLanguage(langcode)}
                className={buttonClassName}
                aria-pressed={isActive}
            >
                <img
                    className="absolute top-0 left-0 h-full w-full object-cover"
                    src={`./images/${langcode}.png`}
                    alt={title}
                />
            </button>
        );
    };

    return (
        <header className="relative w-full">
            <div className="container flex justify-between my-5 ">
                    <img src={logo} className="transition-all duration-300 max-[340px]:w-16 max-[320px]:w-16 w-24 sm:w-32 md:w-32 lg:w-40 xl:w-44" alt="Logo"/>
                <nav className="flex items-center gap-5 sm:gap-3 md:gap-5 lg:gap-5 ">
                    <LanguageButton title="Français" langcode="fr"/>
                    <LanguageButton title="Deutsch" langcode="de"/>
                </nav>
            </div>
        </header>
    );
});



export default Header;